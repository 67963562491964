const PaymentArrowIcon = ({ className }) => (
  <svg className={className} fill="none" height="21" viewBox="0 0 10 21" width="10" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.04332 6.44341L2.92749 5.56008L7.74332 10.3742C7.82095 10.4514 7.88256 10.5431 7.9246 10.6442C7.96664 10.7452 7.98828 10.8536 7.98828 10.963C7.98828 11.0724 7.96664 11.1808 7.9246 11.2818C7.88256 11.3829 7.82095 11.4746 7.74332 11.5517L2.92749 16.3684L2.04416 15.4851L6.56416 10.9642L2.04332 6.44341Z"
      fill="#1D1D1B"
    />
  </svg>
);

export default PaymentArrowIcon;

import { Link } from 'react-router';
import PaymentArrowIcon from './PaymentArrowIcon';

const PaymentMethodInfo = ({ windowWidthSize, paymentMethod, paymentTerm }) => (
  <Link className="payment-method-box" to={windowWidthSize < 768 ? '/profile/billing-infos' : ''}>
    <div className="payment-method-box-item">
      <div className="payment-method-item">
        Method:
        <span className={`payment-method-item-text ${paymentMethod ? 'active' : ''}`}>{paymentMethod ?? 'N/A'}</span>
      </div>
      <div className="payment-method-item">
        Terms:
        <span className={`payment-method-item-text ${paymentTerm ? 'active' : ''}`}>{paymentTerm ?? 'N/A'}</span>
      </div>
    </div>
    <Link className="payment-method-button" to="/profile/billing-infos">
      Manage
    </Link>
    <PaymentArrowIcon className="payment-method-arrow-icon" />
  </Link>
);

export default PaymentMethodInfo;
